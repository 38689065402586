import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { PagesService } from 'src/app/services/pages.service';
import { ResponseMsg } from '../../../interface/response-msg';
import { ErrorService } from '../../../services/error.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {

  selectedPage = '';
  @ViewChild('drawer') drawer: MatDrawer;
  userDetail = localStorage.getItem('user');
  public logoutmsg = ResponseMsg.LOGOUT_MSG;
  public isMenu: boolean = false;
  public siteDetails:any = {};
  public isStudentAttendance: boolean = false;

  constructor(private router: Router, private errService: ErrorService, private pageService: PagesService) {
    router.events.subscribe((()=>{
      let currentPage = router.url.split('/');
      this.selectedPage = currentPage[1].charAt(0).toUpperCase() + currentPage[1].slice(1);
      if(this.selectedPage == 'StudentForm'){
        this.isMenu = false
      }
    }))
   }

  ngOnInit(): void {
    this.pageService.listenIsMenu().subscribe(res => {
      if (res.siteId, res.locationId) {
        this.pageService.siteDetails.subscribe((res: any) => {      
              if (res.statusCode == 200) {
                this.siteDetails = res.siteInfo;
                let userDetails:any = JSON.parse(localStorage.getItem('user'))
                if (res.siteInfo['visitorTypeList'].length == 1 || (userDetails && userDetails?.isStudent && res.siteInfo.isVPassIntegration)) {
                  this.isMenu = false;
                } else {
                  this.isMenu = true;
                }
                res.siteInfo['visitorTypeList'].forEach(element => {
                  if(element.visitorType == "Student Attendance")
                  {
                    this.isStudentAttendance = res.siteInfo.isVPassIntegration || res.siteInfo.isSimonIntegration || res.siteInfo.isWondeIntegration || res.siteInfo.isArborIntegration;
                  }
                });
              }
        })
      } else {
        this.isMenu = true;
      }
    })
    // let currentPage = this.router.url.split('/');
    // this.selectedPage = currentPage[1].charAt(0).toUpperCase() + currentPage[1].slice(1);
  }

  logout() {
    localStorage.clear();
    this.errService.showSuccessMsg(this.logoutmsg);
    this.router.navigate(['/session/welcome-signin']);
  }

  gotoHome() {
    this.drawer.close();
    this.selectedPage = 'Home';
    let visitId = localStorage.getItem('vId');
    if (visitId) {
      var passType: any = ""
      this.router.navigate(['/home', visitId, passType]);
    } else {
      this.router.navigate(['/home']);
    }
  }

  gotoProfile() {
    this.drawer.close();
    this.selectedPage = 'Profile';
    this.router.navigate(['/profile']);
  }

  //qr scanned by guest user and vt screen menu list
  gotoRegister() {
    this.drawer.close();
    this.selectedPage = 'Register';
    this.router.navigate(['/session/register']);
  }

  gotoLogin() {
    localStorage.clear();
    this.drawer.close();
    this.selectedPage = 'Login';
    this.router.navigate(['/session/signin']);
  }

  studentIcon(){
    let visitorTypeList = this.siteDetails.visitorTypeList.filter(res => res.visitorType == "Student Attendance")
    let visitorTypeId = visitorTypeList[0].visitorTypeId;
    let formId = visitorTypeList[0].formId;
    this.isMenu = false;
    if(this.siteDetails.isVPassIntegration){
      this.router.navigate(['/studentForm', formId, this.siteDetails.siteId, this.siteDetails.locationId, visitorTypeId]);
    } else if(this.siteDetails.isSimonIntegration || this.siteDetails.isWondeIntegration || this.siteDetails.isArborIntegration){
      this.router.navigate(['/visitorpass', formId, this.siteDetails.siteId, this.siteDetails.locationId, visitorTypeId]);
    }
}
}
