import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { PagesService } from './../services/pages.service';

@Injectable()
export class visitortypeCountResolver implements Resolve<any> {
    constructor(private pageService: PagesService) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.pageService.getVisitortypeCount(route.params.siteId, route.params.locationId);
    }
}