import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loaderData = new Subject<any>();

  constructor() { }

  loadSpinner(message: any) {
    this.loaderData.next(message);
  }

  listenloadSpinner() {
    return this.loaderData.asObservable();
  }
}
