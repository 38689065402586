export const SECRET_KEY: any = 'HM123';
export const constKeys = {
    SECRET_KEY: 'HM123',
    INTERCOM_APP_ID: 't1849nfa',
    AUTODELETE_DURATION: [
        // {
        //     label: '28 days from last visit',
        //     value: 28
        // },
        // {
        //     label: '3 months from last visit',
        //     value: 3
        // }, 
        {
            label: '1 month from last visit',
            value: 1
        },
        {
            label: '6 months from last visit',
            value: 6
        },
        {
            label: '1 year from last visit',
            value: 12
        },
        {
            label: '2 years from last visit',
            value: 24
        },
        {
            label: 'Unlimited',
            value: 1200
        }
    ],
    MIME_TYPES: {
        IMG: ['image/png', 'image/jpg', 'image/jpeg'],
        DOC: ['application/pdf', 'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
        VID: ['video/mp4', ' video/x-m4v', 'video/*'],
        EXCEL: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'],
    }
};