<div class="dflex all_ctr flex_dir ">
  <mat-drawer-container class="example-container" autosize [hasBackdrop]="true">

    <mat-drawer #drawer class="side_drawer " mode="over">
      <div class="logoimg dflex all_ctr mt-30 mb-20">
        <img class="mt-5 mb-30" src="assets/images/logo.png" alt="logo-img" width="89">
      </div>
      <div class="options dflex flex_dir mt-20" *ngIf="userDetail">
        <button class="btn mt-35 dflex pointer"><img class="imageicon" src="assets/images/homeicon.png"
            alt="home"><label class="rbold text ml-18 fs-14 pointer" (click)="gotoHome()">Home</label></button>
        <button class="btn mt-35 dflex pointer"><img class="imageicon" src="assets/images/profileicon.png"
            alt="home"><label class="rbold text  ml-18 fs-14 pointer" (click)="gotoProfile()">Profile</label></button>
        <button class="btn mt-35 dflex pointer" (click)="logout()"><img class="imageicon" src="assets/images/logout.png"
            alt="home"><label class="rbold text ml-18 fs-14 pointer">Logout</label></button>
      </div>
      <!-- vt screen menu list for qrcode scanned by guest user-->
      <div class="options dflex flex_dir mt-20" *ngIf="!userDetail">
        <button class="btn mt-35 dflex pointer"><img class="imageicon" src="assets/images/register.png"
            alt="home"><label class="rbold text ml-18 fs-14 pointer" (click)="gotoRegister()">Register</label></button>
        <button class="btn mt-35 dflex pointer"><img class="imageicon" src="assets/images/login.png" alt="home"><label
            class="rbold text  ml-18 fs-14 pointer" (click)="gotoLogin()">Login</label></button>
      </div>
    </mat-drawer>
    <div class="w-100 pl-40 pr-40 pt-10 pb-10 header" *ngIf="isMenu">
      <div class="dflex hdr_inner justify_btw">
        <!-- <button mat-icon-button (click)="drawer.toggle()" class="menu_icn" *ngIf="userDetail">
          <mat-icon>menu</mat-icon>
        </button> -->
        <button mat-icon-button (click)="drawer.toggle()" class="menu_icn">
          <mat-icon>menu</mat-icon>
        </button>
        <!-- <label class="rbold hometext" [ngClass.xs]="['fs-14']" [ngClass.sm]="['fs-12']" [ngClass.md]="['fs-12']"
        [ngClass.lg]="['fs-14']">{{selectedPage}}</label> -->
        <label class="rbold hometext" [ngClass.xs]="['fs-14']" [ngClass.sm]="['fs-12']" [ngClass.md]="['fs-12']"
          [ngClass.lg]="['fs-14']" *ngIf="selectedPage != 'Visitortype'">{{selectedPage}}</label>
          <label class="rbold hometext" [ngClass.xs]="['fs-14']" [ngClass.sm]="['fs-12']" [ngClass.md]="['fs-12']"
          [ngClass.lg]="['fs-14']" *ngIf="selectedPage == 'Visitortype' "></label>

          <div *ngIf="selectedPage == 'Visitortype' && isStudentAttendance" (click)="studentIcon()">
            <img src="assets/images/graduate.svg" width="40px">
          </div>

      </div>
    </div>
    <div class="main_container">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-container>

</div>