import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { ErrorService } from './error.service';
import { environment } from '../../environments/environment';

@Injectable()
export class CommonInterceptor implements HttpInterceptor {

  public isOnline: boolean = navigator.onLine;
  public cdnUrl = environment.cdnUrl;

  constructor(private authService: AuthService, private router: Router,
    private errService: ErrorService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    //Setting content type in header if it is not cdn url and if no content type in header.
    if (!request.headers.has('Content-Type') && !request.url.includes(this.cdnUrl)) {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json')
      });
    }

    request = this.addAuthtoken(request);

    if (!this.isOnline) {
      this.errService.showResponseError('Please check the network connection.')
    } else {
      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          console.log(error.status, "INTERCEPTOR ERR");
          if (error.status == 401) {
            // this.errService.showResponseError(error.statusText);
            localStorage.removeItem('auth');
            localStorage.removeItem('user');
            this.router.navigate(["/session/signin"]);
          } else {
            this.errService.showResponseError(error.statusText);
          }
          return throwError(error);
        })
      );
    }

  }

  private addAuthtoken(request: HttpRequest<any>) {
    let auth = localStorage.getItem('auth');
    let ct = localStorage.getItem('ct');

    if (!this.authService.isAuthenticated()) {
      return request;
    }

    return request.clone({
      headers: request.headers.set('Authorization', "Bearer " + auth)
    });

  }
}
