import { Component, OnInit ,ViewChild} from '@angular/core';
import {  NgxScannerQrcodeComponent} from 'ngx-scanner-qrcode';
import { Router } from '@angular/router';
import { PagesService } from './../../../../src/app/services/pages.service';
import { ErrorService } from './../../../../src/app/services/error.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-qrcode-scanner',
  templateUrl: './qrcode-scanner.component.html',
  styleUrls: ['./qrcode-scanner.component.scss']
})
export class QrcodeScannerComponent implements OnInit {
  qrCodeUrl: string = '';
  @ViewChild('action', { static: true }) action: NgxScannerQrcodeComponent;
  hasDevices: boolean = false;
  hasPermission: boolean;
  availableDevices: MediaDeviceInfo[] = [];
  currentDevice: MediaDeviceInfo = null;
  isCheckedOut: boolean = false;
  signedoutstatus = false;
  signedStatus: string;
  public frameConfig: Object = {
    lineWidth: 0,
  };
  public textConfig: Object = {
    font: 'bold 0px serif',
    fillStyle: 'white',
  };

  constructor(private router: Router, private pageService: PagesService, private errService: ErrorService, 
    private location: Location) { }

  ngOnInit(): void {
    this.action.toggleCamera()
    if (!localStorage.getItem('auth')) {
      this.pageService.getGuestToken().subscribe((res: any) => {
        if (res.statusCode == 200) {
          localStorage.setItem('auth', res.guestToken);
        }
      });
    }
  }


 
  onCodeResult(resultString: string) {
    if(!this.qrCodeUrl && resultString){
      this.qrCodeUrl = resultString;
      if (this.qrCodeUrl != '') {
        this.hasPermission = true;
        this.action.toggleCamera()
        let url = this.qrCodeUrl.split("/");
        let locationId = url[url.length - 1];
        let siteId = url[url.length - 2];
        let visitId = localStorage.getItem('vId');
        localStorage.removeItem('goBack');
        if (visitId) {
          if (!this.isCheckedOut)
          this.checkoutVisitor(siteId, locationId, visitId)
        } else {
          this.pageService.checkVisitorTypeCount(siteId, locationId);
        }
      }
      
    }
   
  }

  checkoutVisitor(siteId, locationId, visitId) {
    this.isCheckedOut = true;
    let reqObj = {
      visitId: parseInt(visitId),
    }
    this.pageService.checkout(reqObj, siteId).subscribe((res: any) => {
      if (res.statusCode == 200) {
        if(res.isSignOutForm && res.signOutFormID){
          let visitortypeId = 0;
          this.router.navigate(['/visitorform', res.signOutFormID , siteId,locationId, visitortypeId, ''],{queryParams:{visitId: res.visitID}})
        } else{
          localStorage.setItem('from_page', 'signout');
          localStorage.removeItem('goBack');
          localStorage.removeItem('vId');
          localStorage.removeItem('sId');
          localStorage.removeItem('lId');
          let signedStatus = this.signedoutstatus = true;
          localStorage.setItem('status', 'true');
          let userdetails = localStorage.getItem('userdetails');
          localStorage.setItem('setTransIn', 'setTransIn');
          localStorage.setItem('setTransOut', 'setTransOut');
          if (res.isGuest) {
            // localStorage.clear();
            localStorage.setItem('status', 'true');
            this.router.navigate(['/home']); 
          } else {
            this.router.navigate(['/home']);
          }
        }
        //localStorage.setItem('status', res.statusCode);
      // this.errService.showSuccessMsg(res.statusMessage);
      } else if (res.statusCode == 419) {
        // if the user didn't checked out his previous site and if he scanned different site then we have to
        // checkin to the new site. So redirecting to visitor type
        this.pageService.checkVisitorTypeCount(siteId, locationId);
      } else {
        this.errService.showResponseError(res.statusMessage);
      }
    }, err => {
      this.errService.showError(err);
    })
  }

  goBack() {
    this.action.toggleCamera()
    localStorage.setItem('goBack', 'goBack');
    this.location.back();
  }

  public onError(e: any): void {
    this.hasPermission = false
  }
}
