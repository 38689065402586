<div class="dflex align_ctr autolayout_hdr pt-18 pb-18 pl-40 pr-40 fs-14 rbold">
    <img class="p-9 pointer l_arrw" src="assets/images/left-arrow.png" alt="leftarrow"
     height="11" (click)="goBack()">
     <span class="dflex w-100 justify_ctr">Scan</span>
</div>
<div [hidden]="(!action.isStart && !hasPermission) || action.isLoading">
    <ngx-scanner-qrcode 
    #action="scanner" 
    [frameConfig]="frameConfig" 
    [textConfig]="textConfig" 
    (data)="onCodeResult($event)" 
    (error)="onError($event)">
  </ngx-scanner-qrcode>
</div> 
<div  *ngIf="action.isLoading" class="dflex all_ctr mt-50 pt-50">
  <mat-spinner [diameter]="35" [color]="'primary'"></mat-spinner>
</div>

<div class="app-qrscanner scanner dflex flex_dir m-28" *ngIf = "!action.isStart && !hasPermission && !action.isLoading">
  <div class="center_align mt-10">
      <div class="dflex all_ctr mt-50 ">
          <label class="rregular perm" [ngClass.xs]="['fs-16']" [ngClass.sm]="['fs-18']" [ngClass.md]="['fs-18']"
              [ngClass.lg]="['fs-20']">
              <img class="alertimage mr-8" src="assets/images/alert.png" alt="alert" >
                You denied the camera permission, we can't scan anything without it.
          </label>
      </div>
  </div>
</div>