// export class ResponseMsg {
// }
export const ResponseMsg = {
    REGISTER_MSG: 'Registered and verification link has sent to your email',
    // SIGNIN_MSG: 'Signed in successfully!',
    SIGNIN_MSG: 'Profile Accessed Successfully',
    VERIFICATIONSENT_MSG: 'Verification code sent to your mobile number',
    VERIFICATIONSUCCESS_MSG: 'Verified successfully!',
    EMAILVERIFY_MSG: 'Reset password link sent to your mail',
    RESETPASSWORD_MSG: 'Password reset successfully!',
    LOGOUT_MSG: 'Logged Out Successfully',
    PASS_SET_MSG: 'Password set Successfully',
}