import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from './common.service';
import { map } from 'rxjs/operators';
import { LoaderService } from '../sharedmodules/loader.service';
import { ErrorService } from './error.service';
import { Router } from '@angular/router';
import { Subject, BehaviorSubject } from 'rxjs';
import _isEmpty from 'lodash/isEmpty'

@Injectable({
  providedIn: 'root'
})
export class PagesService {
  isSignOutOnly: boolean;
  private isMenu = new Subject<any>();
  public siteDetails = new BehaviorSubject([]);
  public geoLocInfo:any = {};
  public redirectObj :any ={}

  constructor(private httpClient: HttpClient, private loaderService: LoaderService,
    private errService: ErrorService, private router: Router) { }

  getSiteLocationInfo(siteId, locationId) {
    let data = {siteId, locationId};
    this.isMenu.next(data);
    if (siteId && locationId) {
      let reqUrl = CommonService.getApiUrl('/visitor/sitelocationinfo/' + siteId + '/' + locationId);
      return this.httpClient.get(reqUrl, {
        headers: new HttpHeaders().set('Authorization', "Bearer " + localStorage.getItem('auth'))
      }).pipe(map((res: Response) => res));
    }
  }

  listenIsMenu() {
    return this.isMenu.asObservable()
  }

  getSiteLocationInfoTest(siteId, locationId) {
    let reqUrl = CommonService.getApiUrl('/visitor/sitelocationinfo/' + siteId + '/' + locationId);
    return this.httpClient.get(reqUrl, {
      headers: new HttpHeaders().set('Authorization', "Bearer " + localStorage.getItem('auth'))
    }).pipe(map((res: Response) => res));
  }

  getVisitortypeCount(siteId, locationId) {
    let reqUrl = CommonService.getApiUrl('/visitor/sitelocationinfo/count/' + siteId + '/' + locationId);
    return this.httpClient.get(reqUrl).pipe(map((res: Response) => res));
  }

  getFormDetails(formId) {
    let reqUrl = CommonService.getApiUrl('/visitor/form/' + formId);
    return this.httpClient.get(reqUrl).pipe(map((res: Response) => res));
  }

  getSiteParams(siteId) {
    let reqUrl = CommonService.getApiUrl('/visitor/siteParams/' + siteId);
    return this.httpClient.get(reqUrl).pipe(map((res: Response) => res));
  }

  getAllHosts(data, siteId) {
    let reqUrl = CommonService.getApiUrl('/visitor/host/list/' + siteId);
    return this.httpClient.post(reqUrl, data).pipe(map((res: Response) => res));
  }

  submitForm(data) {
    let reqUrl = CommonService.getApiUrl('/visitor/form/submit');
    return this.httpClient.post(reqUrl, data).pipe(map((res: Response) => {
      this.loaderService.loadSpinner(false);
      return res;
    }));
  }

  getVisitInfo(visitId) {

    let reqUrl = CommonService.getApiUrl('/visitor/visitdetails/' + visitId);
    return this.httpClient.get(reqUrl).pipe(map((res: Response) => res));
  }

  getOrgToken(visitId) {

    let reqUrl = CommonService.getApiUrl('/visitor/getOrgToken/' + visitId);
    return this.httpClient.get(reqUrl).pipe(map((res: Response) => res));
  }
  // getVisitInfo(visitId) {
  //   console.log(localStorage.getItem('guestToken'));

  //   let reqUrl = CommonService.getApiUrl('/visitor/visitdetails/' + visitId);
  //   return this.httpClient.get(reqUrl, {
  //     headers: new HttpHeaders().set('Authorization', "Bearer " + localStorage.getItem('guestToken'))
  //   }).pipe(map((res: Response) => res));
  // }

  checkout(data, siteId) {
    let reqUrl = CommonService.getApiUrl('/visitor/checkout/' + siteId);
    return this.httpClient.put(reqUrl, data).pipe(map((res: Response) => res));
  }

  getProfile() {
    let reqUrl = CommonService.getApiUrl('/visitor/profile');
    return this.httpClient.get(reqUrl).pipe(map((res: Response) => res));
  }

  updateProfile(data) {
    let reqUrl = CommonService.getApiUrl('/visitor/profile');
    return this.httpClient.put(reqUrl, data).pipe(map((res: Response) => {
      this.loaderService.loadSpinner(false);
      return res;
    }));
  }

  getGuestToken() {
    let reqUrl = CommonService.getApiUrl('/visitor/guesttoken');
    return this.httpClient.get(reqUrl).pipe(map((res: Response) => res));
  }

  //Visitor type and visitor form redirection condition check. If the site has only one visitor type, 
  // it has to navigate to visitorform. If more than 1 site it has to show visitor type page to select
  checkVisitorTypeCount(siteId, locationId) {
    var passtype: any = "";
    // this.getVisitortypeCount(siteId, locationId).subscribe((res: any) => {
    //   if (res.statusCode == 200) {
          // if (res.visitorTypeCount == 1) {
          //   this.router.navigate(['/visitorform', res.formId, siteId,
          //     locationId, res.visitorTypeId, passtype])
          // } else {
            // Commented below route to restrict 1sec show up of select visitor type page when user signs in
            // this.router.navigate(['/visitortype', siteId, locationId]);
            this.getSiteLocationInfo(siteId, locationId).subscribe((res: any) => {
              this.geoLocInfo = res.siteInfo?.geoLocInfo
              let userData = JSON.parse(localStorage.getItem('user'))
              if( userData && userData?.isStudent && userData?.studentId && res.siteInfo.isVPassIntegration){
                res.siteInfo['visitorTypeList'] = res.siteInfo['visitorTypeList'].filter(item => item.visitorType == 'Student Attendance')
              }
              this.siteDetails.next(res);
              localStorage.setItem('isSimonIntegration',res.siteInfo.isSimonIntegration);
              localStorage.setItem('isWondeIntegration',res.siteInfo.isWondeIntegration);
              localStorage.setItem('isArborIntegration',res.siteInfo.isArborIntegration);
              if (res.statusCode == 200) {
                  if (res.siteInfo['visitorTypeList'].length == 1) {
                    this.redirectObj = {res,siteId,locationId,userData,passtype}
                    //Redirect process when one visitor type is here
                    this.isSignOutOnly = res.siteInfo['visitorTypeList'][0]['isSignOutOnly']
                    if(res.siteInfo['visitorTypeList'][0]['isGeoLocation'] && this.geoLocInfo.isGeoLocation){
                      this.getLocation(this.redirectObj)
                    } else {
                      this.redirectFn(this.redirectObj)
                    }
                  } else if (res.siteInfo['visitorTypeList'].length == 0) {
                    this.errService.showSuccessMsg("Visitor type is not configured for this site.");
                  } else {
                    this.router.navigate(['/visitortype', siteId, locationId]);
                  }
                // this.siteData = res.siteInfo;
              } else if(res.statusCode == 402) {
                let isGeoLocation = localStorage.getItem('isGeoLocation') ? localStorage.getItem('isGeoLocation') : false
                if(res.siteInfo && this.geoLocInfo.isGeoLocation && isGeoLocation == "true"){
                  this.getLocation({siteId, locationId},true)
                } else {
                  this.autosubmit(siteId, locationId);
                }
                
              } else {
                this.errService.showResponseError(res.statusMessage);
              }
            }, err => {
              this.errService.showError(err);
            });
          // }
    //   }
    //     else {
    //     this.errService.showResponseError(res.statusMessage);
    //   }
    // }, err => {
    //   this.errService.showError(err);
    // });
  }

  redirectFn({res,siteId, locationId,userData,passtype},currentUserLocation={}){
    let siteRadius =  this.geoLocInfo.radiusType == 'm' ? (Number(this.geoLocInfo.radius)/1000).toFixed(1) :this.geoLocInfo.radius;
    if(_isEmpty(currentUserLocation) || this.checkLocation({lat: this.geoLocInfo.lat,lon:this.geoLocInfo.lon},currentUserLocation,siteRadius)){
      if (res.siteInfo['visitorTypeList'][0].visitorType == "Student Attendance") {
        if(res.siteInfo.isSimonIntegration || res.siteInfo.isWondeIntegration || res.siteInfo.isArborIntegration){
          this.router.navigate(['/visitorpass', res.siteInfo['visitorTypeList'][0]['formId'], siteId,
          locationId,res.siteInfo['visitorTypeList'][0].visitorTypeId]);
        } else if(res.siteInfo.isVPassIntegration){
          this.router.navigate(['/studentForm', res.siteInfo['visitorTypeList'][0]['formId'], siteId,
        locationId,res.siteInfo['visitorTypeList'][0].visitorTypeId],{queryParams:{studentId:userData?.studentId ? userData?.studentId : 0}});
        }
      } else{
        this.router.navigate(['/visitorform', res.siteInfo['visitorTypeList'][0]['formId'], siteId,
          locationId, res.siteInfo['visitorTypeList'][0].visitorTypeId, passtype])
      }
    } else {
      this.errService.showResponseError("You are not inside the site allowable radius");
      this.router.navigate(['/home'])
    }
  }

  getLocation(redirectObj:any={},forRegVisitor = false) {
    let currentUserlocation:any = {}
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position)=>{
        currentUserlocation = {lat:position.coords.latitude,lon:position.coords.longitude}
        if(!_isEmpty(redirectObj) && !forRegVisitor ){
          
          if(!_isEmpty(currentUserlocation)){
            this.redirectFn(redirectObj,currentUserlocation)
          } else {
            this.errService.showResponseError("Please allow to access your location.");
            this.router.navigate(['/home'])
          }
        } else if (forRegVisitor){
            let siteRadius =  this.geoLocInfo.radiusType == 'm' ? (Number(this.geoLocInfo.radius)/1000).toFixed(1) :this.geoLocInfo.radius;
          if(!_isEmpty(currentUserlocation)){
            if(this.checkLocation({lat: this.geoLocInfo.lat,lon:this.geoLocInfo.lon},currentUserlocation,siteRadius)){
              this.autosubmit(redirectObj.siteId, redirectObj.locationId);
           } else {
            this.errService.showResponseError("You are not inside the site allowable radius");
            this.router.navigate(['/home'])
           }
          }
          else {
            this.errService.showResponseError("Please allow to access your location.");
            this.router.navigate(['/home'])
          }
        }
      },err=> this.showError(err,this));
      
    } else { 
      this.errService.showResponseError("Please allow to access your location.");
      this.router.navigate(['/home'])
    }
    return currentUserlocation;
  }
  showError(error,parentElement){
    
    switch(error.code) {
      case error.PERMISSION_DENIED:
       parentElement.errService.showResponseError("User denied the request for Geolocation.")
       this.router.navigate(['/home'])
        break;
      case error.POSITION_UNAVAILABLE:
        parentElement.errService.showResponseError("Location information is unavailable.")
        this.router.navigate(['/home'])
        break;
      case error.TIMEOUT:
        parentElement.errService.showResponseError("The request to get user location timed out.")
        this.router.navigate(['/home'])
        break;
      case error.UNKNOWN_ERROR:
        parentElement.errService.showResponseError("An unknown error occurred.")
        this.router.navigate(['/home'])
        break;
    }
  }

  checkLocation(sitelocation,currentUserlocation,reqDistance){
    let distance = this.getDistanceFromLatLonInKm(sitelocation.lat,sitelocation.lon,currentUserlocation.lat,currentUserlocation.lon)
    if(distance<reqDistance){
      return true;
    } else {
      return false
    }
  }

   getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
    var dLon = this.deg2rad(lon2-lon1); 
    var a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2)
      ; 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c; // Distance in km
    return d;
  }
  
   deg2rad(deg) {
    return deg * (Math.PI/180)
  }
  autosubmit(siteId, locationId)
  {
    let reqObj = {
      "siteId": parseInt(siteId),
      "locationId": parseInt(locationId),
      "visitorTypeId": 0,
      formResponse: []
    };
    this.submitForm(reqObj).subscribe((res1: any) => {


      if (res1.statusCode == 200) {
        localStorage.setItem('from_page', 'form');
        if(res1.isSignOutOnly)
        {

          localStorage.removeItem('goBack');
          localStorage.removeItem('vId');
          localStorage.removeItem('sId');
          localStorage.removeItem('lId');
          localStorage.setItem('status', 'true');
          let userdetails = localStorage.getItem('userdetails');
          localStorage.setItem('setTransIn', 'setTransIn');
          localStorage.setItem('setTransOut', 'setTransOut');
          this.router.navigate(['/home']); 
        }
        else
        {
          localStorage.setItem('vId', res1.visitId);
          localStorage.removeItem('sId');
          localStorage.removeItem('lId');
          localStorage.setItem('userName', res1.visitId);
          var passType = ""
          localStorage.setItem('setTransIn', 'setTransIn');
          localStorage.setItem('setTransOut', 'setTransOut');
          this.router.navigate(['/home', res1.visitId, passType]);  
        }
       
        
      } else {
        this.errService.showResponseError(res1.statusMessage);
      }
    }, err => {
      this.errService.showError(err);
    });
  }
  resendVerifyEmail() {
    let reqUrl = CommonService.getApiUrl('/visitor/resendemail');
    return this.httpClient.post(reqUrl, '').pipe(map((res: Response) => {
      this.loaderService.loadSpinner(false);
      return res;
    }));
  }
  getStudents(data) {
    let reqUrl = CommonService.getApiUrl('/student/getstudents');
    return this.httpClient.post(reqUrl, data).pipe(map((res: Response) => res));
  }
  getAbsenceType(data) {
    let reqUrl = CommonService.getApiUrl('/student/getAbsenceTypes');
    return this.httpClient.post(reqUrl, data).pipe(map((res: Response) => res));
  }
  getlatetoschoolpassreasons(data) {
    let reqUrl = CommonService.getApiUrl('/student/getlatetoschoolpassreasons');
    return this.httpClient.post(reqUrl, data).pipe(map((res: Response) => res));
  }
  getleavepassreasons(data) {
    let reqUrl = CommonService.getApiUrl('/student/getleavepassreasons');
    return this.httpClient.post(reqUrl, data).pipe(map((res: Response) => res));
  }
  // Simon
  getincompleteleavepasses(data) {
    let reqUrl = CommonService.getApiUrl('/student/getincompleteleavepasses');
    return this.httpClient.post(reqUrl, data).pipe(map((res: Response) => res));
  }
  addlatetoschoolpass(data) {
    let reqUrl = CommonService.getApiUrl('/student/addlatetoschoolpass');
    return this.httpClient.post(reqUrl, data).pipe(map((res: Response) => res));
  }
  addleavepass(data) {
    let reqUrl = CommonService.getApiUrl('/student/addleavepass');
    return this.httpClient.post(reqUrl, data).pipe(map((res: Response) => res));
  }
  visitorAppFormSubmit(data) {
    let reqUrl = CommonService.getApiUrl('/visitor/form/submit');
    return this.httpClient.post(reqUrl, data).pipe(map((res: Response) => res));
  }
  // Returning Student  get List
  // visitorAppVisitDetails(data) {
  //   let reqUrl = CommonService.getApiUrl('/visitor/form/submit/returningstudent');
  //   return this.httpClient.post(reqUrl, data).pipe(map((res: Response) => res));
  // }
  visitorVisitDetails(siteID, uid) {
    let reqUrl = CommonService.getApiUrl('/visitor/visitdetailsreturningstudent/' + siteID + '/' + uid);
    return this.httpClient.get(reqUrl).pipe(map((res: Response) => res));
  }
  visitorAppReturningStudent(data) {
    let reqUrl = CommonService.getApiUrl('/visitor/form/submit/returningstudent');
    return this.httpClient.put(reqUrl, data).pipe(map((res: Response) => res));
  }
  completePassSubmit(data) {
    let reqUrl = CommonService.getApiUrl('/student/completeleavepass');
    return this.httpClient.post(reqUrl, data).pipe(map((res: Response) => res));
  }

  getStudentList(data){
    let reqUrl = CommonService.getApiUrl('/student/list');
    return this.httpClient.post(reqUrl,data).pipe(map((res: Response) => res));
  }

  getStudentStatus(data){
    let reqUrl = CommonService.getApiUrl('/student/status');
    return this.httpClient.post(reqUrl,data).pipe(map((res: Response) => res));
  }
}
