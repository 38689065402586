
import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './components/core/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './components/core/auth-layout/auth-layout.component';
import { visitortypeCountResolver } from './resolvers/visitortype-count';

export const AppRoutes: Routes = [{
    path: "",
    redirectTo: "session/welcome-signin",
    pathMatch: "full"
},
{
    path: "",
    component: AdminLayoutComponent,
    children: [
        {
            path: "home",
            loadChildren: () => import("./pages/home/home.module").then(m => m.HomeModule)
        },
        {
            path: "home/:visitId/:passType",
            loadChildren: () => import("./pages/home/home.module").then(m => m.HomeModule)
        },
        {
            path: "home/:visitId",
            loadChildren: () => import("./pages/home/home.module").then(m => m.HomeModule)
        },
        {
            path: "profile",
            loadChildren: () => import("./pages/profile/profile.module").then(m => m.ProfileModule)
        },
        { //visitortype screen with menu
            path: "visitortype/:siteId/:locationId",
            loadChildren: () => import("./pages/visitor-type/visitor-type.module").then(m => m.VisitorTypeModule)
        },
        {
            path: 'visitorpass',
            loadChildren: () => import("./pages/visiter-pass/visiter-pass.module").then(m => m.VisiterPassModule),
        },
        {
            path: 'studentForm',
            loadChildren: () => import("./pages/student-attendance/student-attendance.module").then(m => m.StudentAttendanceModule),
        }
    ]
},
{
    path: "",
    component: AuthLayoutComponent,
    children: [{
        path: "session",
        loadChildren: () => import("./pages/session/session.module").then(m => m.SessionModule)
    },
    {
        path: 'visitorform',
        loadChildren: () => import("./pages/visitor-form/visitor-form.module").then(m => m.VisitorFormModule)
    },
    {
        path: "qrscanner",
        loadChildren: () => import("./pages/qrscanner/qrscanner.module").then(m => m.QrscannerModule)
    },
    {
        path: "viewbadge",
        loadChildren: () => import("./pages/viewbadge/viewbadge.module").then(m => m.ViewbadgeModule)
    },
    {
        path: "viewbadge/:visitId",
        loadChildren: () => import("./pages/viewbadge/viewbadge.module").then(m => m.ViewbadgeModule)
    },
    // {
    //     path: "visitortype/:siteId/:locationId",
    //     loadChildren: () => import("./pages/visitor-type/visitor-type.module").then(m => m.VisitorTypeModule)
    // },
    {
        path: "kiosk/visitortype/:siteId/:locationId",
        loadChildren: () => import("./pages/visitor-type/visitor-type.module").then(m => m.VisitorTypeModule),
        //resolve: { count: visitortypeCountResolver }
    }]
}];